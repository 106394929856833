import { BaseHeaderTabs } from 'hive-mui-utils';
import _fp from 'lodash/fp';
import { useTabs } from '../hooks';

export function HeaderTabs(): JSX.Element {
  const tabs = useTabs();
  if (_fp.size(tabs) < 2) {
    return <></>;
  }

  return <BaseHeaderTabs tabs={tabs} />;
}
