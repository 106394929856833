import { HeaderTab } from 'hive-mui-utils';
import { useContext, useMemo } from 'react';
import { TAB_COPILOT, TAB_DATA_SOURCES, TAB_EVENTS } from '../constants';
import { CopilotContext, ICopilotContext } from 'hive-copilot-react';
import { DataContext } from '../contexts';

export function useTabs(): HeaderTab[] {
  const { hasAdminRole } = useContext<ICopilotContext>(CopilotContext);
  const { hasAnalyticsReaderRole } = useContext(DataContext);

  return useMemo<HeaderTab[]>(() => {
    const t = [TAB_COPILOT];

    if (hasAdminRole) {
      t.push(TAB_DATA_SOURCES);
    }

    if (hasAnalyticsReaderRole) {
      t.push(TAB_EVENTS);
    }

    return t;
  }, [hasAdminRole, hasAnalyticsReaderRole]);
}
