export const en = {
  'app.title': 'Data Copilot',

  common: {
    ok: 'OK',
    cancel: 'Cancel',
    create: 'Create',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
  },

  guest: {
    title: 'To sign in as a guest, please provide your email address.',
    button: 'Continue as guest',
    email: {
      placeholder: 'Enter your email address',
      invalid: 'Invalid email address',
    },
  },

  header: {
    title: 'Data Copilot',

    menu: {
      disconnect: 'Disconnect',
    },
  },

  tabs: {
    chat: 'Chat',
    datasources: 'Data Sources',
    events: 'Events',
  },

  chat: {
    create_data_source: 'Create a data source...',
  },

  datasources: {
    select: {
      title: 'Please select a data source:',
      label: 'Data sources',
      placeholder: '',
      button: 'Select',
    },
    empty: {
      title: 'No data sources',
      message:
        'There are no data sources assigned to you. Please contact your administrator.',
    },
  },

  metrics: {
    title: 'Session History',
    search: {
      label: '',
      placeholder: 'Search',
    },
    session: {
      summary: {
        session_id: 'ID',
        user_id: 'User',
        start: 'Start',
        duration: 'Duration',
        version: 'Version',
        platform: 'Platform',
        browser: 'Browser',
      },
    },
    events: {
      columns: {
        time: 'Time',
        event: 'Event',
        elapsed: 'Elapsed',
        elapsedTooltip: 'Milliseconds',
        info: 'Info',
      },
    },
    filters: {
      period_range: {
        title: 'Period Range',
        start: 'Start Date / Time',
        end: 'End Date / Time',
        last_24_hours: 'Last 24 hours',
        last_7_days: 'Last 7 days',
        last_30_days: 'Last 30 days',
        date_range: 'Date Range',
      },
      users: {
        title: 'Users',
        all: 'All',
      },
      sessions: {
        title: 'Sessions',
        all: 'All',
      },
      events: {
        title: 'Events',
        all: 'All',
      },
    },
  },

  sequences: {
    title: 'Sequence <i>{{id}}</i>',
    summarize: {
      description:
        'This sequence is executed once, at the start of each new discussion. It consists of a single prompt to Copilot.',
      prompts: {
        summarize:
          'This prompt asks Copilot to describe your data model and to suggest questions for the user.',
      },
    },

    question: {
      description:
        'This sequence of prompts is executed for every question to Copilot. It consists of the chained prompts.',
      prompts: {
        augment:
          'This prompt asks Copilot to rephrase your question based on your question. It takes the conversation history and any extra instructions you specify.',
        query:
          'The prompt asks Copilot to build a query statement (SQL) to run on your data base on the question and any extra instructions you specify.',
        interpret:
          'The prompt asks Copilot to interpret the data based on question, the query statement (SQL), the results, and any extra instructions you specify.',
        component:
          'The prompt asks Copilot what type of UI component is best used to display the information.',
      },
    },
  },
};
