import { initI18n } from 'hive-react-utils';
import _ from 'lodash';

import { en, fr } from '../i18n';
import { en as muiEn, fr as muiFr } from 'hive-mui-utils';
import { en as oidcEn, fr as oidcFr } from 'hive-oidc-react';
import { en as copilotEn, fr as copilotFr } from 'hive-copilot-react';

import 'moment/locale/fr';

initI18n({
  en: _.merge(
    // Multi-line
    muiEn,
    copilotEn,
    oidcEn,
    en
  ),

  fr: _.merge(
    // Multi-line
    muiFr,
    copilotFr,
    oidcFr,
    fr
  ),
});
