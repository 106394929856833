import { Event, Session } from 'hive-analytics-react';
import _ from 'lodash';
import _fp from 'lodash/fp';
import moment from 'moment';
import { FilterContextProps } from './FilterContext';

export const formatData = _fp.flow([
  (v: any) => JSON.stringify(v, null, 4),
  _fp.replace(/\n/g, '<br>'),
  // _fp.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'),
  _fp.replace(/ /g, '&nbsp;'),
]);

const REGEX_UUID =
  /([0-9a-fA-F]{8})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{4})-([0-9a-fA-F]{12})/;

export const formatShortUUid = (uuid: string = '') => {
  const match = REGEX_UUID.exec(uuid);
  if (!match) {
    return uuid;
  }

  const [, part1] = match;
  return part1;
};

export interface FormatDateAndTimeOptions {
  format?: string;
  language?: string;
}

export function formatDateAndTime(
  dateTime?: moment.Moment | string | number,
  options?: FormatDateAndTimeOptions
): string {
  if (!dateTime) {
    return '';
  }

  const m = moment(dateTime);
  if (options?.language) {
    m.locale(options.language);
  }

  return m.format(options?.format || 'YYYY-MM-DD LTS');
}

export function formatDuration(
  duration?: moment.Duration | string | number
): string {
  if (!duration) {
    return '';
  }

  const d = moment.duration(duration);

  const components = [
    Math.floor(d.asSeconds() / 3600),
    Math.floor((d.asSeconds() % 3600) / 60),
    d.asSeconds() % 60,
  ];

  return _fp.flow([
    _fp.map(
      _fp.flow([
        //
        Math.floor,
        (n: number) => n.toString().padStart(2, '0'),
      ])
    ),
    _fp.join(':'),
  ])(components);
}

type ValueFormatter = (v: any) => string;
interface ValueFormatters {
  [key: string]: ValueFormatter;
}

const VALUE_FORMATTERS: ValueFormatters = {
  string: _.identity,
  number: (v: number) => v.toString(),
  object: JSON.stringify,
  default: JSON.stringify,
};

function formatValue(v: any) {
  const formatter = _.get(VALUE_FORMATTERS, typeof v, VALUE_FORMATTERS.default);
  return formatter(v);
}

const valueMatches = (search: string) => (value: string) => {
  value = _.toLower(_.deburr(value));
  return _.includes(value, search);
};

const eventMatches = ({
  search,
  userIds,
  sessionIds,
  eventIds,
}: FilterContextProps) => {
  search = _.toLower(_.deburr(search));
  return (e: Event) => {
    if (!userIds.has(e.properties.userId || '')) {
      return false;
    }

    if (!sessionIds.has(e.properties.sessionId)) {
      return false;
    }

    if (!eventIds.has(e.properties.eventId)) {
      return false;
    }

    const values = _fp.flow([
      // Use some visible fields only
      _fp.pick([
        'eventId',
        'appName',
        'appVersion',
        'userAgent',
        'elapsed',
        'data',
      ]),
      _fp.values,
      _fp.map(formatValue),
    ])(e.properties);

    return _.some(values, valueMatches(search));
  };
};

export const filterEvents = (events: Event[], filter: FilterContextProps) => {
  return _.filter(events, eventMatches(filter));
};

const sessionsMatches = ({
  userIds,
  sessionIds,
  eventIds,
}: FilterContextProps) => {
  return (s: Session) => {
    if (!userIds.has(s.userId || '')) {
      return false;
    }

    if (!s.id || !sessionIds.has(s.id)) {
      return false;
    }

    if (_.isEmpty(_.intersection(Array.from(eventIds), s.eventIds))) {
      return false;
    }

    // Don't use the search for sessions, since we might be hiding sessions where
    // the events (which we might not have here), would indeed show up.
    return true;
  };
};

export const filterSessions = (
  sessions: Session[],
  filter: FilterContextProps
) => {
  return _.filter(sessions, sessionsMatches(filter));
};
