import { PropsWithRef, useMemo } from 'react';

import _ from 'lodash';
import { formatShortUUid } from '../utilities';
import { CheckboxGroup, CheckboxGroupEntry } from '../../CheckboxGroup';

export interface EventsProps {
  eventIds: string[];

  selectedEventIds: Set<string>;
  setSelectedEventIds: (value: Set<string>) => void;
}

export function Events({
  eventIds,
  selectedEventIds,
  setSelectedEventIds,
}: PropsWithRef<EventsProps>): JSX.Element {
  const values = useMemo<CheckboxGroupEntry[]>(
    () =>
      _.map(eventIds, (name: string) => ({
        id: name,
        label: formatShortUUid(name),
      })),
    [eventIds]
  );

  return (
    <CheckboxGroup
      titleId="metrics.filters.events.title"
      allId="metrics.filters.events.all"
      values={values}
      selectedValues={selectedEventIds}
      setSelectedValues={setSelectedEventIds}
    />
  );
}
