import { PropsWithRef } from 'react';
import { Popover, Stack } from '@mui/material';

import moment from 'moment';

import { PeriodRange } from './PeriodRange';
import { Users } from './Users';
import { Events } from './Events';
import { Sessions } from './Sessions';

export interface FiltersProps {
  open: boolean;
  onClose: () => void;

  anchorEl?: any;

  startTime: moment.Moment;
  setStartTime: (value: moment.Moment) => void;

  endTime: moment.Moment;
  setEndTime: (value: moment.Moment) => void;

  userIds: string[];
  selectedUserIds: Set<string>;
  setSelectedUserIds: (value: Set<string>) => void;

  sessionIds: string[];
  selectedSessionIds: Set<string>;
  setSelectedSessionIds: (value: Set<string>) => void;

  eventIds: string[];
  selectedEventIds: Set<string>;
  setSelectedEventIds: (value: Set<string>) => void;
}

export function Filters({
  open,
  anchorEl,

  onClose,

  startTime,
  setStartTime,

  endTime,
  setEndTime,

  userIds,
  selectedUserIds,
  setSelectedUserIds,

  sessionIds,
  selectedSessionIds,
  setSelectedSessionIds,

  eventIds,
  selectedEventIds,
  setSelectedEventIds,
}: PropsWithRef<FiltersProps>): JSX.Element {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={onClose}
      className="metrics"
      elevation={6}
    >
      <Stack direction="row" padding={2} spacing={8}>
        <PeriodRange
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
        />

        <Stack direction="row" spacing={4}>
          <Users
            userIds={userIds}
            selectedUserIds={selectedUserIds}
            setSelectedUserIds={setSelectedUserIds}
          />

          <Sessions
            sessionIds={sessionIds}
            selectedSessionIds={selectedSessionIds}
            setSelectedSessionIds={setSelectedSessionIds}
          />

          <Events
            eventIds={eventIds}
            selectedEventIds={selectedEventIds}
            setSelectedEventIds={setSelectedEventIds}
          />
        </Stack>
      </Stack>
    </Popover>
  );
}
