import { useCallback } from 'react';
import { MenuItem, useMediaQuery, Theme, Divider } from '@mui/material';
import { useOidcProviderFromStorage } from 'hive-oidc-react';
import { useConnection } from 'hive-react-utils';
import { useTranslation } from 'react-i18next';
import { LoggedConnectionService } from 'hive-analytics-react';
import { HeaderTab } from 'hive-mui-utils';
import { useNavigate } from 'react-router-dom';
import { useTabs } from '../hooks';

function DisconnectItem(): JSX.Element | null {
  const { t } = useTranslation();
  const { username } = useConnection() || {};
  const provider = useOidcProviderFromStorage();

  const onClick = useCallback(() => {
    LoggedConnectionService.releaseBee()
      .then(() => {
        if (provider?.logoutUri) {
          const url = new URL(provider.logoutUri);
          url.hash = '';
          window.location.assign(url);
        }
      })
      .catch(console.error);
  }, [provider?.logoutUri]);

  if (!username) {
    return null;
  }

  return <MenuItem onClick={onClick}>{t('header.menu.disconnect')}</MenuItem>;
}

interface TabMenuItemProps {
  tab: HeaderTab;
}

function TabMenuItem({ tab }: TabMenuItemProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate(tab.path), [navigate, tab.path]);

  return <MenuItem onClick={onClick}>{t(tab.labelId)}</MenuItem>;
}

function TabsAsMenuItems(): JSX.Element {
  const displayTabsInMenu = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const tabs = useTabs();

  if (!displayTabsInMenu) {
    return <></>;
  }

  return (
    <>
      {tabs.map((t: HeaderTab) => (
        <TabMenuItem key={t.path} tab={t} />
      ))}

      <Divider />
    </>
  );
}

export function HeaderMenuItems(): JSX.Element {
  return (
    <>
      <TabsAsMenuItems />
      <DisconnectItem />
    </>
  );
}
