import { CircularProgress, Stack } from '@mui/material';

export function Loading() {
  return (
    <Stack
      flexGrow={1}
      alignContent="center"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Stack>
  );
}
