import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell as BaseTableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { PropsWithRef, useCallback, useState } from 'react';
import {
  formatDateAndTime,
  formatDuration,
  formatShortUUid,
} from './utilities';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Events } from './Events';
import { useTranslation } from 'react-i18next';
import { Session as ISession } from 'hive-analytics-react';
import { useCurrentLanguage } from 'hive-react-utils';

const TableCell = styled(BaseTableCell)(({ theme }) => ({
  padding: 0,
  '&:not(.last-of-type)': {
    paddingRight: theme.spacing(1),
  },

  borderBottom: 'unset',
  textAlign: 'left',

  color: theme.palette.primary.main,

  '.MuiTableHead-root &': {
    ...theme.typography.body2,
    fontWeight: 500,
  },

  '.MuiTableBody-root &': {
    ...theme.typography.body2,
    fontWeight: 300,
  },
}));

export interface SessionProps {
  session: ISession;
}

export function Session({ session }: PropsWithRef<SessionProps>): JSX.Element {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const onExpanded = useCallback(
    (_event: any, newExpanded: boolean) => setExpanded(newExpanded),
    []
  );

  const language = useCurrentLanguage();

  return (
    <Accordion id={session.id || ''} onChange={onExpanded}>
      <AccordionSummary expandIcon={<ExpandMore color="primary" />}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('metrics.session.summary.session_id')}</TableCell>
              <TableCell>{t('metrics.session.summary.user_id')}</TableCell>
              <TableCell>{t('metrics.session.summary.start')}</TableCell>
              <TableCell width="10%">
                {t('metrics.session.summary.duration')}
              </TableCell>
              <TableCell>{t('metrics.session.summary.version')}</TableCell>
              <TableCell>{t('metrics.session.summary.platform')}</TableCell>
              <TableCell>{t('metrics.session.summary.browser')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>{formatShortUUid(session?.id)}</TableCell>
              <TableCell>{formatShortUUid(session?.userId)}</TableCell>
              <TableCell>
                {formatDateAndTime(session.startTime, { language })}
              </TableCell>
              <TableCell>{formatDuration(session?.duration)}</TableCell>
              <TableCell>{session?.appVersion}</TableCell>
              <TableCell>{session?.platform}</TableCell>
              <TableCell>{session?.browser}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionSummary>

      <AccordionDetails>
        {expanded && session.id && <Events sessionId={session.id} />}
      </AccordionDetails>
    </Accordion>
  );
}
