import { PropsWithRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Event, useEventsByDateAndSessionId } from 'hive-analytics-react';
import _fp from 'lodash/fp';

import { CircularProgress, Stack, styled, useTheme } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';

import { FilterContext } from './FilterContext';
import { filterEvents, formatDateAndTime, formatDuration } from './utilities';

const Container = styled(Stack)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function Info({ data }: { data?: any }): JSX.Element | null {
  const theme = useTheme();
  const json = useMemo(() => JSON.stringify(data, null, 4), [data]);

  if (_fp.isEmpty(data)) {
    return null;
  }

  return (
    <SyntaxHighlighter
      language="json"
      style={{
        hljs: {
          ...theme.typography.body2,
          color: theme.palette.primary.main,
          fontFamily: 'monospace',
          fontSize: '11px',
          backgroundColor: 'unset',
        },
      }}
    >
      {json}
    </SyntaxHighlighter>
  );
}

export interface EventsProps {
  sessionId: string;
}

export function Events({ sessionId }: PropsWithRef<EventsProps>): JSX.Element {
  const { t } = useTranslation();
  const filter = useContext(FilterContext);
  const { startTime, endTime } = filter;
  const rawEvents = useEventsByDateAndSessionId(startTime, endTime, sessionId, {
    debounce: true,
    debounceWait: 5000,
    debounceMaxWait: 30000,
  });
  const events = useMemo(
    () => filterEvents(rawEvents || [], filter),
    [rawEvents, filter]
  );

  const baseColumns = useMemo<Column<Event>[]>(
    () => [
      {
        title: t('metrics.events.columns.time'),
        field: 'created',
        render: (data: Event) => formatDateAndTime(data.created),
      },
      {
        title: t('metrics.events.columns.event'),
        field: 'properties.eventId',
      },
      {
        title: t('metrics.events.columns.elapsed'),
        tooltip: t('metrics.events.columns.elapsedTooltip'),
        render: (e: Event) => formatDuration(e.properties.elapsed),
      },
      {
        title: t('metrics.events.columns.info'),
        render: (e: Event) => <Info data={e.properties.data} />,
      },
    ],
    [t]
  );

  // Material-table relies on some inline styles, so we make sure our
  // columns have the styles we want
  const theme = useTheme();

  const cellStyle = useMemo(
    () => ({
      ...theme.typography.body2,
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      padding: theme.spacing(1),
    }),
    [theme]
  );

  const headerStyle = useMemo(
    () => ({
      ...theme.typography.body2,
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      fontWeight: 600,
      padding: theme.spacing(1),
    }),
    [theme]
  );

  const columns = useMemo<Column<Event>[]>(
    () =>
      _fp.map(
        _fp.merge({
          searchable: false,
          align: 'left',
          headerStyle,
          cellStyle,
        })
      )(baseColumns) as Column<Event>[],
    [baseColumns, cellStyle, headerStyle]
  );

  if (!rawEvents) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <MaterialTable
      columns={columns}
      data={events}
      components={{
        Container,
        Toolbar: () => null,
      }}
      options={{
        pageSize: 10,
        pageSizeOptions: [10],
        paginationPosition: 'top',
        paginationAlignment: 'center',
      }}
    />
  );
}
