import { ThemeOptions } from '@mui/material';

// import backgroundUrl from '../assets/oidc-background-dark.png';
import backgroundUrl from '../assets/oidc-background.png';

export const themeOptions: ThemeOptions = {
  components: {
    MuiStack: {
      styleOverrides: {
        root: {
          '&.oidc.providers, &.main-content-container': {
            backgroundImage: `url('${backgroundUrl}')`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          },
        },
      },
    },
  },
};
