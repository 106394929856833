import {
  styled,
  Button,
  FormControl,
  FormHelperText as BaseFormHelperText,
  Input as BaseInput,
  Collapse,
  Card as BaseCard,
  CardContent as BaseCardContent,
  CardActions as BaseCardActions,
  Typography,
  Stack,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../contexts';
import { useHiveConfig } from 'hive-react-utils';
import { LoggedConnectionService } from 'hive-analytics-react';

const REGEX_EMAIL = /^[^@]+@[^@]+\.[^@]+$/;

const Main = styled(Stack)({
  justifyContent: 'center',
  flexGrow: 1,
});

const Card = styled(BaseCard)({
  alignSelf: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
});

const CardContent = styled(BaseCardContent)({});

const CardActions = styled(BaseCardActions)({
  justifyContent: 'center',
});

const Input = styled(BaseInput)({
  '& input': {
    textAlign: 'center',
  },
});

const FormHelperText = styled(BaseFormHelperText)({
  marginLeft: 'unset',
  width: '100%',
  textAlign: 'center',
});

export function GuestSignIn(): JSX.Element {
  const { t } = useTranslation();
  const { featureFlags } = useContext(DataContext);
  const config = useHiveConfig();

  const [invalidEmail, setInvalidEmail] = useState('');

  const [email, setEmail] = useState('');
  const onEmailChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setEmail(event.currentTarget.value),
    []
  );

  const isEmailValid = useCallback((e: string) => {
    return !e || e.match(REGEX_EMAIL);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isEmailValid(email)) {
        return setInvalidEmail('');
      }

      setInvalidEmail(t('guest.email.invalid'));
    }, 500);
    return () => {
      setInvalidEmail('');
      clearTimeout(timeout);
    };
  }, [email, isEmailValid, t]);

  const onContinue = useCallback(() => {
    fetch('/tokens/everyone')
      .then((r) => r.text())
      .then((token) => {
        LoggedConnectionService.signInWithToken(config!, email, token);
      })
      .catch(console.error);
  }, [config, email]);

  if (!config || !featureFlags?.supportGuests) {
    return <></>;
  }

  return (
    <Main className="guest-signin">
      <Card>
        <CardContent>
          <Stack spacing={1}>
            <Typography className="title"> {t('guest.title')}</Typography>

            <FormControl variant="filled" fullWidth>
              <Input
                className="email"
                required
                fullWidth
                placeholder={t('guest.email.placeholder')}
                value={email}
                inputProps={{
                  'data-1p-ignore': '',
                }}
                onChange={onEmailChanged}
              />

              <Collapse in={!!invalidEmail}>
                <FormHelperText error={true}>{invalidEmail}</FormHelperText>
              </Collapse>
            </FormControl>
          </Stack>
        </CardContent>

        <CardActions>
          <Button
            className="continue-button"
            disabled={!email || !!invalidEmail}
            variant="contained"
            onClick={onContinue}
          >
            {t('guest.button')}
          </Button>
        </CardActions>
      </Card>
    </Main>
  );
}
