import createPolyfill from 'web-speech-cognitive-services';
import SpeechRecognition from 'react-speech-recognition';
import { AppFeatureFlags } from '../contexts';

export async function initializeAzureSpeechServices(
  featureFlags: AppFeatureFlags
): Promise<boolean> {
  try {
    if (!featureFlags?.azureSpeechRecognition) {
      return false;
    }

    const credentials = await fetch('/tokens/azure-speech').then((res) => {
      if (res.status !== 200) {
        throw new Error(res.statusText);
      }
      return res.json();
    });

    const { SpeechRecognition: polyfill } = createPolyfill({ credentials });
    SpeechRecognition.applyPolyfill(polyfill);

    return true;
  } catch (e) {
    console.error(e);
  }

  return false;
}
