import { OidcContextProps } from 'hive-oidc-react';
import { CopilotContextProviderProps } from 'hive-copilot-react';
import { HeaderTab } from 'hive-mui-utils';
import moment from 'moment';

export enum Paths {
  Root = '/',
  Copilot = '/copilot',
  DataSources = '/datasources',
  Events = '/events',
  OIDC = '/oidc',
}

export const TAB_COPILOT: HeaderTab = {
  path: Paths.Copilot,
  labelId: 'tabs.chat',
};

export const TAB_DATA_SOURCES: HeaderTab = {
  path: Paths.DataSources,
  labelId: 'tabs.datasources',
};

export const TAB_EVENTS: HeaderTab = {
  path: Paths.Events,
  labelId: 'tabs.events',
};

export const STORAGE_KEY_OIDC_PROVIDER_ID = 'oidc-provider';

const ERROR_MESSAGES: string[] = [
  'signin.error.message1',
  'signin.error.message1',
];

export const OIDC_CONTEXT_PROPS: OidcContextProps = {
  loggedInPath: Paths.OIDC,
  loggedOutPath: Paths.Root,
  errorPath: Paths.Root,
  storageKey: STORAGE_KEY_OIDC_PROVIDER_ID,
  errorMessages: ERROR_MESSAGES,
};

export const COPILOT_CONTEXT: CopilotContextProviderProps = {
  api: {
    actionSummarize: { name: 'copilot_prompt.summarize' },
    actionQuestion: {
      name: 'copilot_prompt.question',
    },
    actionExplainSql: { name: 'copilot_prompt.explainSql' },
    dataSource: {
      createTimeout: moment.duration(15, 'minutes').asMilliseconds(),
      updateTimeout: moment.duration(15, 'minutes').asMilliseconds(),
    },
  },

  roles: {
    user: 'CopilotUser',
    admin: 'CopilotAdmin',
    dev: 'CopilotDevUser',
    editor: 'CopilotEditor',
  },

  sequences: [
    {
      id: 'summarize',
      descriptionLabel: 'sequences.summarize.description',
      prompts: [
        {
          id: 'summarize',
          descriptionLabel: 'sequences.summarize.prompts.summarize',
        },
      ],
    },
    {
      id: 'question',
      descriptionLabel: 'sequences.question.description',
      prompts: [
        {
          id: 'augment',
          descriptionLabel: 'sequences.question.prompts.augment',
        },
        {
          id: 'query',
          descriptionLabel: 'sequences.question.prompts.query',
        },
        {
          id: 'interpret',
          descriptionLabel: 'sequences.question.prompts.interpret',
        },
        {
          id: 'component',
          descriptionLabel: 'sequences.question.prompts.component',
        },
      ],
    },
  ],
};
