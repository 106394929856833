export const fr = {
  'app.title': 'Data Copilot',

  common: {
    ok: 'OK',
    cancel: 'Annuler',
    créer: 'Créer',
    edit: 'Modifier',
    supprimer: 'Supprimer',
    save: 'Sauvegarder',
    yes: 'Oui',
    no: 'Non',
  },

  guest: {
    title:
      "Pour vous connecter en tant qu'invité, veuillez indiquer votre adresse électronique.",
    button: "Continuer en tant qu'invité",
    email: {
      placeholder: 'Saisissez votre adresse électronique',
      invalid: 'Adresse électronique invalide',
    },
  },

  header: {
    title: 'Data Copilot',

    menu: {
      disconnect: 'Disconnect',
    },
  },

  tabs: {
    chat: 'Chat',
    datasources: 'Sources de données',
    events: 'Événements',
  },

  chat: {
    create_data_source: 'Créer une source de données...',
  },

  datasources: {
    select: {
      title: 'Veuillez sélectionner une source de données :',
      label: 'Sources de données',
      placeholder: '',
      button: 'Select',
    },
    empty: {
      title: 'Pas de sources de données',
      message:
        'Aucune source de données ne vous a été attribuée. Veuillez contacter votre administrateur.',
    },
  },

  metrics: {
    title: 'Historique des sessions',
    search: {
      label: '',
      placeholder: 'Recherche',
    },
    session: {
      summary: {
        session_id: 'Identifiant',
        user_id: 'Utilisateur',
        start: 'Début',
        duration: 'Durée',
        app: 'Application',
        version: 'Version',
        platform: 'Plateforme',
        browser: 'Navigateur',
      },
    },
    events: {
      columns: {
        time: 'Date/Heure',
        event: 'Événement',
        elapsed: 'Durée',
        info: 'Info',
      },
    },
    filters: {
      period_range: {
        title: 'Plage de périodes',
        start: 'Date et heure de début',
        end: 'Date et heure de début fin',
        last_24_hours: 'Dernières 24 heures',
        last_7_days: 'Derniers 7 jours',
        last_30_days: 'Derniers 30 jours',
        date_range: 'Plage de dates',
      },
      users: {
        title: 'Utilisateurs',
        all: 'Tous',
      },
      sessions: {
        title: 'Sessions',
        all: 'Tous',
      },
      events: {
        title: 'Événements',
        all: 'Tous',
      },
    },
  },

  sequences: {
    title: 'Séquence <i>{{id}}</i>',
    summarize: {
      description:
        'Cette séquence est exécutée une fois, au début de chaque nouvelle discussion. Elle consiste en une seule invite à Copilote.',
      prompts: {
        summarize:
          "Cette invite demande à Copilot de décrire votre modèle de données et de suggérer des questions à l'utilisateur.",
      },
    },

    prompt: {
      description:
        "Cette séquence d'invites est exécutée pour chaque question posée au Copilote. Elle se compose des invites suivantes, enchaînées dans l'ordre :",
      prompts: {
        augment:
          "Cette invite demande à Copilot de reformuler votre question en fonction de celle-ci. Il prend en compte l'historique de la conversation et toutes les instructions supplémentaires que vous avez spécifiées.",
        query:
          "L'invite demande à Copilot de construire une requête (SQL) à exécuter sur votre base de données en fonction de la question et de toute instruction supplémentaire que vous aurez spécifiée.",
        interpret:
          "L'invite demande à Copilot d'interpréter les données en fonction de la question, de l'instruction de requête (SQL), des résultats et de toute instruction supplémentaire que vous avez spécifiée.",
        component:
          "L'invite demande à Copilot quel type de composant d'interface utilisateur est le mieux adapté pour afficher les informations.",
      },
    },
  },
};
