import {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Button, Stack, Typography } from '@mui/material';
import {
  CopilotChatContextProvider,
  CopilotContext,
  ICopilotContext,
  IDataSource,
  useMyDataSources,
} from 'hive-copilot-react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';

import { Paths } from '../constants';
import { DataSourceSelection } from './DataSourceSelection';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Loading } from './Loading';

const CopilotChat = lazy(() =>
  import('hive-copilot-react').then(({ CopilotChat: c }) => ({ default: c }))
);

export function Chat(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataSources = useMyDataSources();
  const { hasAdminRole } = useContext<ICopilotContext>(CopilotContext);

  const { dataSourceId: rawDataSourceId = '' } = useParams();
  const dataSourceId = useMemo(
    () => _.toLower(decodeURIComponent(rawDataSourceId)),
    [rawDataSourceId]
  );

  const dataSource = useMemo(
    () =>
      _.find(
        dataSources,
        (d: IDataSource) => _.toLower(d.properties.id) === dataSourceId
      ),
    [dataSources, dataSourceId]
  );

  const getQuestionExtraParams = useCallback(
    () => [moment.tz.guess(), dataSource?.properties.id],
    [dataSource?.properties.id]
  );

  const getSummarizeExtraParams = useCallback(
    () => [dataSource?.properties.id],
    [dataSource?.properties.id]
  );

  const getExplainSqlExtraParams = useCallback(
    () => [dataSource?.properties.id],
    [dataSource?.properties.id]
  );

  const [chatId, setChatId] = useState(uuid);
  const onNewConversation = useCallback(() => {
    setChatId(uuid());
    navigate(Paths.Copilot);
  }, [navigate]);

  const onCreateDataSource = useCallback(() => {
    navigate(Paths.DataSources);
  }, [navigate]);

  if (dataSourceId && dataSource) {
    return (
      <Suspense fallback={<Loading />}>
        <CopilotChatContextProvider
          chatId={chatId}
          getActionSummarizeExtraParams={getSummarizeExtraParams}
          getActionQuestionExtraParams={getQuestionExtraParams}
          getActionExplainSqlExtraParams={getExplainSqlExtraParams}
        >
          <CopilotChat onNewConversation={onNewConversation} />
        </CopilotChatContextProvider>
      </Suspense>
    );
  }

  const count = dataSources?.length || 0;

  if (count === 1) {
    return (
      <Navigate to={`${Paths.Copilot}/${dataSources![0].properties.id}`} />
    );
  }

  // Do we have more than one available, then show the selection screen
  if (count > 1) {
    if (dataSourceId) {
      return <Navigate to={Paths.Copilot} />;
    }

    return <DataSourceSelection dataSources={dataSources!} />;
  }

  if (hasAdminRole) {
    // If there are no data source and user is an admin, show the create data source button
    return (
      <Stack flexGrow={1} justifyContent="center" alignItems="center">
        <Button variant="contained" onClick={onCreateDataSource}>
          {t('chat.create_data_source')}
        </Button>
      </Stack>
    );
  }

  // No data sources and user is NOT an admin, show no source available
  return (
    <Stack flexGrow={1} spacing={1} justifyContent="center" alignItems="center">
      <Typography variant="h5">{t('datasources.empty.title')}</Typography>

      <Typography variant="h6" maxWidth="480px">
        {t('datasources.empty.message')}
      </Typography>
    </Stack>
  );
}
