import {
  SignInWithOidc as BaseSignInWithOidc,
  OidcContextProvider,
} from 'hive-oidc-react';
import { OIDC_CONTEXT_PROPS } from '../constants';

export function SignInWithOidc(): JSX.Element {
  return (
    <OidcContextProvider {...OIDC_CONTEXT_PROPS}>
      <BaseSignInWithOidc />
    </OidcContextProvider>
  );
}
