import { Navigate, Route, Routes } from 'react-router-dom';
import { useDebugValue, useTitleEffect } from 'hive-react-utils';

import { DataContext } from './contexts';

import { COPILOT_CONTEXT, Paths } from './constants';

import {
  Content,
  LayoutWithHeaderAndFooter,
  MuiContextProvider,
  muiThemeOptions,
} from 'hive-mui-utils';

import {
  HeaderTabs,
  HeaderMenuItems,
  Chat,
  DataSources,
  Metrics,
  OidcProviders,
  SignInWithOidc,
} from './components';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { themeOptions } from './theme';
import { useContext, useEffect, useState } from 'react';
import {
  CopilotContext,
  CopilotContextProvider,
  ICopilotContext,
} from 'hive-copilot-react';
import { LocationTracker } from 'hive-analytics-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { initializeAzureSpeechServices } from './services/AzureSpeechServices';

const THEME = createTheme(themeOptions, muiThemeOptions);

function Routing(): JSX.Element {
  const { hasAdminRole } = useContext<ICopilotContext>(CopilotContext);
  const { hasAnalyticsReaderRole } = useContext(DataContext);
  return (
    <>
      {/* Logs analytics events about the route of the user goes to. */}
      <LocationTracker />

      <Routes>
        <Route
          path={`${Paths.Copilot}/:dataSourceId`}
          element={<Content Component={Chat} />}
        />

        <Route path={Paths.Copilot} element={<Content Component={Chat} />} />

        <Route
          path={Paths.DataSources}
          element={
            hasAdminRole ? (
              <Content Component={DataSources} />
            ) : (
              <Navigate to={process.env.PUBLIC_URL} />
            )
          }
        />

        <Route
          path={Paths.Events}
          element={
            hasAnalyticsReaderRole ? (
              <Content Component={Metrics} />
            ) : (
              <Navigate to={process.env.PUBLIC_URL} />
            )
          }
        />

        <Route
          path={Paths.OIDC}
          element={
            <LayoutWithHeaderAndFooter>
              <SignInWithOidc />
            </LayoutWithHeaderAndFooter>
          }
        />

        <Route path={Paths.Root} element={<Navigate to={Paths.Copilot} />} />

        <Route
          path="*"
          element={<Navigate to={process.env.PUBLIC_URL}></Navigate>}
        />
      </Routes>
    </>
  );
}

export default function App() {
  useTitleEffect('app.title');

  const { featureFlags, appVersion } = useContext(DataContext);
  useDebugValue(appVersion?.version, 'Application Version', '');

  // Enable Azure Speech Service if feature is enabled and we can get a token
  const [supportSpeechRecognition, setSupportSpeechRecognition] =
    useState(false);
  useEffect(() => {
    initializeAzureSpeechServices(featureFlags).then(
      setSupportSpeechRecognition
    );
  }, [featureFlags]);

  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiContextProvider
          DisconnectedComponent={OidcProviders}
          TabsComponent={HeaderTabs}
          HeaderMenuItems={HeaderMenuItems}
        >
          <CopilotContextProvider
            {...COPILOT_CONTEXT}
            supportSpeechRecognition={supportSpeechRecognition}
            disableDataSourceEditing={featureFlags?.disableDataSourceEditing}
          >
            <Routing />
          </CopilotContextProvider>
        </MuiContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
