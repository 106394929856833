import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoginService } from './services';

// The following just need to be imported to start things
import './services/LocalizationService';
import { BrowserRouter } from 'react-router-dom';
import { DataContextProvider } from './contexts';

const APP_NAME = 'data-copilot';
const KEY_TOKEN = 'hive-copilot-token';
const KEY_TOKEN_EXPIRATION = 'hive-copilot-token-expiration';
const KEY_USERNAME = 'hive-copilot-username';

LoginService.init({
  appName: APP_NAME,
  keyToken: KEY_TOKEN,
  keyTokenExpiration: KEY_TOKEN_EXPIRATION,
  keyUsername: KEY_USERNAME,
}).then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <DataContextProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
          <App />
        </BrowserRouter>
      </DataContextProvider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
