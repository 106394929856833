import { lazy, Suspense } from 'react';
import { OidcContextProvider } from 'hive-oidc-react';
import { Loading } from './Loading';
import { OIDC_CONTEXT_PROPS } from '../constants';
import { GuestSignIn } from './GuestSignIn';

const BaseOidcProviders = lazy(() =>
  import('hive-oidc-react').then(({ OidcProviders: c }) => ({ default: c }))
);

export function OidcProviders(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <OidcContextProvider {...OIDC_CONTEXT_PROPS}>
        <BaseOidcProviders>
          <GuestSignIn />
        </BaseOidcProviders>
      </OidcContextProvider>
    </Suspense>
  );
}
