import { useCallback, useMemo } from 'react';
import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { IDataSource, Select } from 'hive-copilot-react';
import _fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../constants';

export interface DataSourceSelectionProps {
  dataSources: IDataSource[];
}
export function DataSourceSelection({
  dataSources,
}: DataSourceSelectionProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const dataSourceId = event.target.value as string;
      navigate(_fp.toLower(`${Paths.Copilot}/${dataSourceId}`));
    },
    [navigate]
  );

  const items = useMemo(
    () =>
      _fp.flow([
        _fp.map('properties.id'),
        _fp.map((id: string) => ({ [id]: id })),
        _fp.assignAll,
      ])(dataSources),
    [dataSources]
  );

  return (
    <Stack flexGrow={1} justifyContent="center" alignItems="center">
      <Stack spacing={1}>
        <Typography>{t('datasources.select.title')}</Typography>

        <Select
          required
          labelId="datasources.select.label"
          placeholderId="datasources.select.placeholder"
          onChange={onChange}
          items={items}
        />
      </Stack>
    </Stack>
  );
}
