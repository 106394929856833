import { Paths } from '../constants';
import { lazy, Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from './Loading';

const BaseDataSources = lazy(() =>
  import('hive-copilot-react').then(({ DataSources: c }) => ({ default: c }))
);

export function DataSources(): JSX.Element {
  const navigate = useNavigate();
  const chatWithDataSource = useCallback(
    (dataSourceId: string) => {
      navigate(`${Paths.Copilot}/${dataSourceId}`);
    },
    [navigate]
  );

  return (
    <Suspense fallback={<Loading />}>
      <BaseDataSources chatWithDataSource={chatWithDataSource} />
    </Suspense>
  );
}
