import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, PropsWithRef, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

export interface CheckboxGroupEntry {
  id: string;
  label: string;
}

export interface CheckboxGroupProps<T extends CheckboxGroupEntry> {
  values: T[];

  titleId: string;
  allId?: string;

  selectedValues: Set<string>;
  setSelectedValues: (value: Set<string>) => void;

  exclusive?: boolean;

  children?: ReactNode | ReactNode[];
}

export function CheckboxGroup<T extends CheckboxGroupEntry>({
  values,
  titleId,
  allId,
  selectedValues,
  setSelectedValues,
  exclusive,
  children,
}: PropsWithRef<CheckboxGroupProps<T>>): JSX.Element {
  const { t } = useTranslation();

  const isChecked = useCallback(
    (value: string) => selectedValues.has(value),
    [selectedValues]
  );

  const setChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newSet: Set<string> = new Set(selectedValues);

      if (checked) {
        if (exclusive) {
          newSet.clear();
        }

        newSet.add(event.target.value);
      } else if (!exclusive) {
        newSet.delete(event.target.value);
      }

      setSelectedValues(newSet);
    },
    [exclusive, selectedValues, setSelectedValues]
  );

  const areAllChecked = useCallback(
    () => values.length === selectedValues.size,
    [selectedValues, values]
  );

  const onAllChecked = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectedValues(new Set(_.map(values, 'id')));
      } else {
        setSelectedValues(new Set());
      }
    },
    [setSelectedValues, values]
  );

  return (
    <Stack>
      {/* Not sure why we need the left=-1, but it aligns things */}
      <Typography variant="h6" marginBottom={1} marginLeft={-1}>
        {t(titleId)}
      </Typography>

      {/* <FormControl> */}
      {allId && (
        <>
          <FormControlLabel
            control={
              <Checkbox checked={areAllChecked()} onChange={onAllChecked} />
            }
            label={t(allId)}
          />
          <Stack paddingTop={1} paddingBottom={1}>
            <Divider flexItem />
          </Stack>
        </>
      )}

      {_.map(values, (value: T) => (
        <FormControlLabel
          key={value.id}
          control={
            <Checkbox
              name={value.id}
              value={value.id}
              checked={isChecked(value.id)}
              onChange={setChecked}
            />
          }
          label={value.label}
        />
      ))}

      {children}
      {/* </FormControl> */}
    </Stack>
  );
}
